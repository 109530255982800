import { bootstrapApplication } from "@angular/platform-browser";
import { appConfig } from "./app.config";
import { AppComponent } from "./app.component";
import { registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/it";
import localeFrExtra from "@angular/common/locales/extra/it";
import {environment} from "@/environments";

registerLocaleData(localeFr, "it-IT", localeFrExtra);

console.log('Environrment', environment.env);

bootstrapApplication(AppComponent, appConfig).catch((err) => {
  console.error(err);
});
